<template>
  <div>
    <el-table v-loading="loading" :data="roles">
      <el-table-column prop="name" min-width="150"></el-table-column>
      <template v-for="roleGroup in roleGroups">
        <el-table-column v-if="roleGroup" :key="roleGroup.id" :prop="roleGroup.id" :label="roleGroup.name" header-align="center" align="center" >
          <template slot-scope="scope">
            <el-checkbox :disabled="!getItem(scope.row.id, roleGroup.id).allowEdit" v-model="getItem(scope.row.id, roleGroup.id).enabled" @change="handleCheck(scope.row.id, roleGroup.id)"></el-checkbox>
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
import tableResize from '@/mixins/tableResize.js';
import accessRightsApi from '@/api/administration/accessRights';

export default {
  name: 'AccessRights',
  mixins: [tableResize],
  data() {
    return {
      loading: false,
      roles: [],
      roleGroups: [],
      items: [],
      isCustomTableColumns: true,
    };
  },
  async mounted() {
    await this.getData();
    setTimeout(() => {
      this.tableHeight = this.getTableHeight();
      this.customColumnResize();
    }, 100);
  },
  methods: {
    async getData() {
      this.loading = true;
      const getResponse = await accessRightsApi.get();
      if (getResponse.data) {
        this.roles = getResponse.data.roles;
        this.roleGroups = getResponse.data.roleGroups;
        this.items = getResponse.data.items;
      }
      this.loading = false;
    },
    getItem(roleId, roleGroupId) {
      const items = this.items.filter((x) => x.roleId === roleId && x.roleGroupId === roleGroupId);
      if (items.length === 1) {
        return items[0];
      }
      return null;
    },
    async handleCheck(roleId, roleGroupId) {
      const item = this.getItem(roleId, roleGroupId);
      await accessRightsApi.save({ items: [item] });
    },

  }
};
</script>

<style scoped lang="scss">
</style>
